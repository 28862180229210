<template>
  <div>
    <div>
      <div class="form-group" v-bind:class="{ 'has-error': $v.vcode.$error }">
        <input
          class="form-control mt-5 inputa"
          @input="onInput"
          :value="value"
          placeholder="Enter your 6 digit code"
          required
        />

        <!-- <span
          class="help-block"
          v-if="
            ($v.vcode.$error && !$v.vcode.required) ||
              !$v.vcode.minLength ||
              !$v.vcode.maxLength
          "
          >Enter a valid 6 digit code</span
        >
        <span class="help-block" v-if="$v.vcode.$error && !$v.vcode.numeric"
          >Enter your 6 digit code</span
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { numeric } from "vuelidate/lib/validators";
import { minLength } from "vuelidate/lib/validators";
import { maxLength } from "vuelidate/lib/validators";

export default {
  name: "step2",
  components: {},
  data() {
    return {
      vcode: null,
    };
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  validations: {
    vcode: {
      required,
      numeric,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
    form: ["vcode"],
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },
    onInput(e) {
      //this.$v.form.$touch();
      this.$emit("on-input", e.target.value);
    },
  },
};
</script>

<style scoped>
.inputa {
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  height: calc(1.6em + 1.625rem + 2px);
  border: 1px solid #e9ecef;
  border-radius: 0.375rem;
}
.inputa-label {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  text-align: left;
}
</style>
