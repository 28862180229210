<template>
  <div>
    <div>
      <b-navbar toggleable="lg" type="dark" class="fixed-top">
        <div class="container">
          <router-link
            :to="{
              path: '/',
            }"
            class="navbar-brand text-white"
            >SKIPIL</router-link
          >

          <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <div class="close-icon py-1">✖</div> -->

          <!-- <i v-if="expanded" class="fas fa-times"></i> -->
          <!-- <i v-else class="fas fa-bars"></i> -->
          <b-navbar-toggle target="nav-collapse" class="text-white">
            <template #default="{ expanded }">
              <i v-if="expanded" style="font-style:normal;"><i class="icon-cancel-1" style="margin-top: 2px;"></i></i>
              <i v-else>///</i>
            </template>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <router-link
                    exact
                    class="nav-link"
                    :to="{
                      path: '/',
                    }"
                    >Home</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    to="/About"
                    :active="$route.name == 'about'"
                    class="nav-link"
                    >About</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :to="{
                      path: '/',
                      hash: '#services',
                    }"
                    >Services</router-link
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    v-b-modal.modal-contact
                    
                    >Contact</a
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    to="/Careers"
                    :active="$route.name == 'careers'"
                    class="nav-link"
                    >Careers</router-link
                  >
                </li>
              </ul>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </div>
</template>

<script>

export default {
  // mounted: function() {
  //   console.log("testing out");
  //   if (this.$route.path === "/") {
  //     console.log("route is home");
  //     this.homePage = true;
  //   } else {
  //     console.log("route not home");
  //     this.homePage = false;
  //   }
  // },

  name: "navigation",
  components: {},
  data() {
    return {
      clicked: false,
      homePage: false,
      contactModal: false,
      methods: {},
 
    };
  },
};
</script>

<style scoped>
@import '../css/skipil-icono.css';
.navbar {
  background-color: #000000;
}
.nav-link {
  color: #ffffff;
}

a.navbar-brand.logo:hover {
  color: #fd173d;
}

/* hide close when burger shown */
.navbar-toggler.collapsed .close-icon {
  display: none;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  display: inline;
}
.navbar-dark .navbar-toggler {
  border-color: black;
}

@media screen and (max-width: 767px) {
  .navbar-nav {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: #fff;
    font-size: 34px;
    margin-left: 20px;
  }
}
a.nav-link.router-link-active {
  color: #fff !important;
}
</style>
