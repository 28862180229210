<template>
  <div>
    <div class="container mt-9 mb-5">
      <section class="pt-8 pt-md-11">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <b-container class="bv-example-row">
                <b-row>
                  <b-col sm="5" class="col-img">
                    <b-row>
                      <b-col sm="12" class="mm-15">
                        <h1 class="mt-5">Start with a name</h1>
                        <vue-typer
                          class="h2 mb-5"
                          :text="[
                            'Is google taken?',
                            'Lets go with Skipil...',
                            'Super cool business',
                          ]"
                          :repeat="Infinity"
                          :shuffle="false"
                          initial-action="typing"
                          :pre-type-delay="70"
                          :type-delay="70"
                          :pre-erase-delay="2000"
                          :erase-delay="250"
                          erase-style="select-all"
                          :erase-on-complete="false"
                          caret-animation="blink"
                        ></vue-typer>
                        <p class="mt-5">
                          We help business’s find the solutions they need to get
                          rolling in the right direction. The simple things can
                          make a big difference!
                        </p>
                      </b-col>
                    </b-row>

                    <b-row class="mt-5 mml-0">
                      <b-col sm="8" style="" class="">
                        <b-form-input
                          aria-describedby="input-1-live-feedback"
                          placeholder="Enter name"
                          class="inputa"
                          name="TextBoxName"
                          type="text"
                          id="TextBoxName"
                          size="20"
                          value="Skipil"
                          v-model="searchvalue"
                          @keyup.enter.native="getResults()"
                        ></b-form-input>
                      </b-col>
                      <b-col sm="4">
                        <b-button
                          type="submit"
                          variant="primary"
                          style="font-weight:600; padding: .8125rem 1.25rem; width:100%;"
                          class="btn btn-primary mt-53"
                          name="ButtonNameLookup"
                          value="ABN Name Lookup"
                          id="ButtonNameLookup"
                          v-on:click="getResults()"
                          >Search</b-button
                        >
                      </b-col>
                    </b-row>
                    <b-row v-if="searchvalueavailable">
                      <b-col sm="12">
                        <p class="mt-3 text-danger">
                          You must enter a word.
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="1"></b-col>

                  <b-col sm="6" class="mt-5 pt-3 info-col">
                    <b-row>
                      <b-col sm="12">
                        <p v-if="outcome && !isSkipil">{{ outcome }}</p>
                      </b-col>
                    </b-row>

                    <b-row v-if="available">
                      <b-col sm="12">
                        <div
                          class="card card-border card-border-green shadow-lg"
                        >
                          <div class="card-body">
                            <!-- List group -->
                            <div class="list-group list-group-flush">
                              <div
                                class="list-group-item d-flex align-items-center"
                                style="border:none;"
                              >
                                <!-- Text -->
                                <div class="mr-auto">
                                  <!-- Heading -->
                                  <p class="font-weight-bold mb-1">
                                    {{ upperSearchValue }}
                                  </p>

                                  <!-- Text -->
                                  <p class="font-size-sm text-muted mb-0">
                                    Status: Available
                                  </p>
                                </div>

                                <!-- check -->
                                <span class="icon icon-xs icon-soft-check icon-circle mr-3">
                                  <i class="icon-ok" style="margin-top: 4px;"></i>
                                </span>
                                <!-- check -->
                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>

                    <h1 v-if="isSkipil">
                      WOW what an awesome buisiness name! It must be worth an
                      absolute fortune! ;)
                    </h1>

                    <b-row class="mt-2" v-if="exists">
                      <b-col sm="12">
                        <div class="card card-border shadow-lg">
                          <div class="card-body">
                            <!-- List group -->
                            <div class="list-group list-group-flush">
                               <div
                                class="list-group-item d-flex align-items-center"
                                style="border:none;"
                              >
                                <!-- Text -->
                                <div class="mr-auto">
                                  <!-- Heading -->
                                  <p class="font-weight-bold mb-1">
                                    <strong>{{ takenMatch.Name }}</strong>
                                  </p>

                                  <!-- Text -->
                                  <p class="font-size-sm text-muted mb-0">
                                    Status: Taken - ABN: {{ takenMatch.Abn }}
                                  </p>
                                </div>

                                 <!-- X -->
                                <span class="icon icon-xs icon-soft-x icon-circle mr-3">
                                  <i class="icon-cancel" style="margin-top: 2px;"></i>
                                </span>
                                <!-- X -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>

                    <!-- This exists value is hiding other buisness view if true -->
                    <b-row class="mt-5" v-if="similarresults.length > 0">
                      <b-col sm="12">
                        <p>
                          Here are a few similarly registered business’s:
                        </p>

                        <div class="card card-border shadow-lg">
                          <div class="card-body">
                            <!-- List group -->
                            <div class="list-group list-group-flush">
                              <div
                                class="list-group-item d-flex align-items-center"
                                v-for="(result, index) in similarresults.filter(
                                  (result) => result.Name !== upperSearchValue
                                )"
                                :key="`result-${index}`"
                              >
                                <!-- Text -->
                                <div class="mr-auto">
                                  <!-- Heading -->
                                  <p class="font-weight-bold mb-1">
                                    {{ result.Name }}
                                  </p>

                                  <!-- Text -->
                                  <p class="font-size-sm text-muted mb-0">
                                    ABN: {{ result.Abn }}
                                  </p>
                                </div>
                                 <!-- X -->
                                <span class="icon icon-xs icon-soft-x icon-circle mr-3">
                                  <i class="icon-cancel" style="margin-top: 2px;"></i>
                                </span>
                                <!-- X -->


                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
          <!-- / .row -->
        </div>
        <!-- / .container -->
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsonp from "jsonp";
import { VueTyper } from "vue-typer";
export default {
  name: "businesschecker",
  components: { VueTyper },
  data() {
    return {
      guidId: "" ,
      searchvalue: null,
      upperSearchValue: null,
      searchvalueavailable: false,
      similarresults: [],
      takenMatch: [],
      available: false,
      exists: false,
      check: false,
      isSkipil: false,
      outcome: "",
    };
  },
  methods: {
    getResults() {
      let self = this;
      this.guidId = process.env.VUE_APP_ABR_K
      

      this.similarresults = [];

      // this.takenMatch.splice(0);

      let similarresults = this.similarresults;

      this.exists = false;
      this.available = false;

      // if a value is present
      if (self.searchvalue) {
        // hide validation message
        self.searchvalueavailable = false;

        // convert the search string to uppercase
        self.upperSearchValue = self.searchvalue.toUpperCase();

        // check if upper is skipil
        if (self.upperSearchValue == "SKIPIL") {
          // show funny message
          self.isSkipil = true;
        } else {
          // dont show funny message
          self.isSkipil = false;
          // get data from abr
          var url =
            "https://abr.business.gov.au/json/MatchingNames.aspx?name=" +
            this.searchvalue +
            "&maxResults=20&guid=" +
            this.guidId;

          jsonp(url, null, function(err, data) {
            if (err) {
              console.error(err.message);
            } else {
              data.Names.forEach((obj) => {
                similarresults.push({
                  Name: obj.Name.toUpperCase(),
                  Abn: obj.Abn,
                });
              });

              console.log(similarresults);

              // .find will find a value and return a json object, some will return a boolean
              self.exists = similarresults.some(
                (d) => d.Name === self.upperSearchValue
              );
              self.takenMatch = similarresults.find(
                (d) => d.Name === self.upperSearchValue
              );
              console.log(self.takenMatch);

              if (self.exists) {
                console.log("Name is already taken");
                self.available = false;
                self.outcome =
                  "Ahhhh damn! This business name is already taken.";
              } else {
                console.log("Name is available");
                self.available = true;
                self.outcome = "Woohoo! This business name looks like its available.";

                var name = self.upperSearchValue.replace(/\s/g, "");

                var instaEndpoint =
                  "https://www.instagram.com/" + name + "/?__a=1";

                axios
                  .get(instaEndpoint)
                  .then(function(response) {
                    if (response) {
                      console.log("Instagram Account is taken");
                    }
                  })
                  .catch(function(error) {
                    if (error) {
                      console.log("Name is available");
                    }
                  });
              }
            }
          });
        }
      } else {
        // if there is no value in the input field
        // show validation message by setting it to true
        self.searchvalueavailable = true;
      }
    },
  },
};
</script>

<style scoped>
@import '../../css/skipil-icono.css';
.mt-9 {
  margin-top: 9rem;
}
.widget-title::before {
  position: absolute;
  left: 0;
  top: 3px;
  height: 2%;
  width: 4px;
  background: #fd173d;
  content: "";
}
div.rounded,
img.rounded {
  border-radius: 0.625rem !important;
}
.pt-md-8,
.py-md-8 {
  padding-top: 3rem !important;
}
.text-muted {
  color: #869ab8 !important;
}
.border-bottom {
  border-bottom: 1px solid #f1f4f8 !important;
}
.border-top {
  border-top: 1px solid #f1f4f8 !important;
}

.social-iconi {
  font-size: 20px;
  color: #869ab8;
}

@media screen and (max-width: 767px) {
  .display-4 {
    font-size: 2rem;
  }
  .mt-9 {
    margin-top: 6rem;
  }
  .lead {
    font-size: 1rem;
  }
  .btn {
    padding: 18px;
    font-size: 21px;
  }
  .info-col {
    margin-right: 0 !important;
    margin-top: 20px !important;
  }
  .download-img {
    width: 340px;
    margin-left: 0 !important;
    padding-right: 20px !important;
  }
  .mtm-1 {
    margin-top: 10px;
  }
  .col-img {
    padding-left: 0;
  }
  h1 {
    font-size: 1.6rem;
  }
  .mm-15 {
    margin: 15px;
  }
  .mml-0 {
    margin-left: 0;
  }
}
.btn-primary {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.4375rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card-frame {
  border: 0.0625rem solid #e7eaf3;
  box-shadow: none;
  transition: 0.3s;
  text-decoration: none;
}
.text-dark {
  color: #1e2022 !important;
}
.router-link {
  color: #377dff;
  text-decoration: none;
}

.card-frame-highlighted,
.card-frame:hover {
  border-color: rgba(55, 125, 255, 0.7);
}

@media screen and (max-width: 767px) {
  .apply-link {
    text-align: right !important;
    top: -32px;
  }
  .router-link {
    padding-bottom: 0px !important;
  }
  #ButtonNameLookup {
    margin-top: 20px;
  }
  .mr-15 {
    margin-right: 15px;
  }
}
.inputa {
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  height: calc(1.6em + 1.625rem + 2px);
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
}
.inputa-label {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  text-align: left;
}

.mt-53 {
  margin-top: 2px;
}

.text-success {
  color: #2ad77d !important;
}

.list-group-flush {
  border-radius: 0;
}

.list-group {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.card-body {
  display: block;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex: 1 1 auto;
  padding: 2rem;
  color: #000;
}

.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.align-items-center {
  -webkit-box-align: center !important;
  align-items: center !important;
}

.card-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-top-width: 2px;
  border-bottom-width: calc(0.375rem - 2px);
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #fd173d;
  border-bottom-color: transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.card-border-green::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-top-width: 2px;
  border-bottom-width: calc(0.375rem - 2px);
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #42ba96;
  border-bottom-color: transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.font-weight-bold {
  font-weight: 600 !important;
}
.card {
  border: none !important;
}

.icon-soft-check {
    color: #42ba96;
    /* background: rgba(55,125,255,.1); */
    background: rgba(66,186,150,.1);
}
.icon-soft-x {
    color: #df4759;
    background: rgba(223,71,89,.1);
    
}


.icon.icon-xs {
    font-size: .75rem;
    width: 1.75rem;
    height: 1.75rem;
}
.icon-circle {
    border-radius: 50%;
}

.icon {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
}

</style>
