<template>
  <div class="multistepverificiation">
    <form-wizard
      ref="wizard"
      title=""
      subtitle=""
      style="padding: 10px;"
      @on-complete="onComplete"
      @on-loading="setLoading"
      @on-change="nextStep"
      @on-validate="handleValidation"
      @on-error="handleErrorMessage"
      color="#335eea"
      error-color="#335eea"
    >
      <tab-content
        title="Personal details"
        :before-change="() => validateStep('step1')"
      >
        <step1 ref="step1" @on-validate="mergePartialModels"></step1>
      </tab-content>
      <!-- <tab-content title="Verify" :before-change="() => validateStep('step2')"> -->
      <!-- validateAsync -->
      <tab-content title="Verify" :before-change="validateAsync">
        <p class="text-center">
          Your one time password has been sent to:<br />
          +61{{ this.finalModel.mobile }}
        </p>

        <div class="text-center">
          <button @click="goBack" type="button" class="btn btn-link">
            Wrong number?
          </button>
        </div>

        <step2
          ref="step2"
          @on-validate="mergePartialModels"
          :value="vcode"
          @on-input="handleInput"
        ></step2>
        <button type="button" class="btn btn-link" @click="resendCode">
          Resend one time password
        </button>
        <p v-if="resent" style="color: green;">Verification code resent</p>
      </tab-content>
      <tab-content title="Submit" :before-change="() => validateStep('step3')">
        <step3 ref="step3" @on-validate="mergePartialModels"></step3>
      </tab-content>
      <tab-content title="Thank you">
        <step4 ref="step4"></step4>
      </tab-content>

      <div class="loader" v-if="loadingWizard"></div>
      <div v-if="errorMsg">
        <span class="error">{{ errorMsg }}</span>
      </div>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer mt-3 text-right">
          <wizard-button
            v-if="props.activeTabIndex === 0"
            class="wizard-footer"
            :style="props.fillButtonStyle"
            style="padding:1rem 1rem;"
            @click.native="props.nextTab()"
            >Next</wizard-button
          >

          <wizard-button
            v-if="props.activeTabIndex === 1"
            :style="props.fillButtonStyle"
            @click.native="props.nextTab()"
            style="padding:1rem 1rem;"
            >Verify</wizard-button
          >

          <wizard-button
            v-if="props.activeTabIndex === 2"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            @click.native="props.nextTab()"
            style="padding:1rem 1rem;"
            >Submit</wizard-button
          >
        </div>
      </template>
    </form-wizard>
    <!-- <pre>{{ finalModel }}</pre> -->
  </div>
</template>

<script>
import axios from "axios";
//local registration
import step1 from "./Step1";
import step2 from "./Step2";
import step3 from "./Step3";
import step4 from "./Step4";

import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "MultiBuilder",
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    step1,
    step2,
    step3,
    step4,
  },
  data() {
    return {
      validationcode: false,
      loadingWizard: false,
      txtDirty: false,
      lastDirty: false,
      errorMsg: null,
      finalModel: {},
      vcode: "",
      resent: "",
      zapHook: "https://hooks.zapier.com/hooks/catch/7349379/oe6qkuh/",
      startVerify: "https://verify-1970-s09nfz.twil.io/start-verify",
      checkVerify: "https://verify-1970-s09nfz.twil.io/check-verify",
    };
  },
  validations: {},
  methods: {
    // hideModal() {
    //     this.$refs.wizard.prevTab();
    //     this.$refs['modal-center'].hide()
    // },
    validateStep(name) {
      // var currentTab = 0;
      // currentTab = this.$refs.wizard.activeTabIndex;
      // if(currentTab ===1){
      //   this.validateAsync()
      // }

      var refToValidate = this.$refs[name];
      // console.log(refToValidate);
      return refToValidate.validate();
    },
    mergePartialModels(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        //console.log("tab is valid");
        this.finalModel = Object.assign({}, this.finalModel, model);
        // console.log(this.finalModel);
      }
    },
    goBack() {
      this.$refs.wizard.prevTab();
    },
    nextStep() {
      var currentTab = null;

      //var finalHook = ""

      currentTab = this.$refs.wizard.activeTabIndex;
      //console.log("the current tab is" + currentTab);

      //console.log("Verification sent before = " + this.firstVerifyInitiated)
      //if (currentTab === 0 && this.finalModel.mobile) {
      if (currentTab === 0 && !this.txtDirty && this.finalModel.mobile) {
        // console.log(this.finalModel);

        // submit the first amount of data
        // axios
        //   .post(
        //     this.zapHook,
        //     this.finalModel,
        //     { headers: { Accept: "application/json" } }
        //   )
        //   .then(function(response) {
        //     console.log(response);
        //   });

          // eslint-disable-next-line no-unused-vars
          axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
          .post(this.zapHook, this.finalModel)
          .then(function(response) {
            console.log(response);
          });



        // send request to twillo to validate
        this.startValidation();
      }

      if (currentTab === 1) {
        // console.log(this.finalModel);
        this.validateAsync();
      }

      if (
        currentTab === 2 &&
        this.finalModel.buisiness &&
        this.finalModel.website &&
        this.finalModel.lookingfor &&
        !this.lastDirty
      ) {


         // eslint-disable-next-line no-unused-vars
         axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
          .post(this.zapHook, this.finalModel)
          .then(function(response) {
            console.log(response);
            console.log("posting final");
          });


        // axios
        //   .post(this.zapHook, this.finalModel, {
        //     headers: { Accept: "application/json" },
        //   })
        //   .then(function(response) {
        //     console.log(response);
        //     console.log("posting final");
            // this.isclickaway = true;
        //   });
        // this.lastDirty = true;
      }

      if (currentTab === 3) {
        console.log("In the last tab");
      }
    },
    onComplete: function() {
      alert("Yay. Done!");
    },
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    handleValidation: function() {},
    // handleValidation: function(isValid, tabIndex) {
    //    console.log("Tab: " + tabIndex + " valid: " + isValid);
    // },
    handleErrorMessage: function(errorMsg) {
      this.errorMsg = errorMsg;
    },
    handleInput(value) {
      this.vcode = value;
      //console.log(this.vcode);
    },
    resendCode() {
      this.startValidation();
      this.resent = true;
    },
    verifyNumber() {
      //this.validationcode = true;

      var auMobilePrefix = "+61";
      var m = this;
      m.mobileNumber = "";
      m.mobileNumber = auMobilePrefix.concat(this.finalModel.mobile);
      // console.log(m.mobileNumber);

      const code = this.vcode;

      const data = new URLSearchParams();
      data.append("to", m.mobileNumber);
      data.append("verification_code", code);

      //fetch("https://verify-2896-dfs53r.twil.io/check-verify", {

      fetch(this.checkVerify, {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            //  console.log(json.message);
            this.validationcode = true;
          } else {
            //  console.log(json.message);
            this.validationcode = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startValidation() {
      if (this.finalModel.mobile) {
        console.log("the current tab is: " + this.$refs.wizard.activeTabIndex);
        var auMobilePrefix = "+61";
        var m = this;
        m.mobileNumber = "";
        m.mobileNumber = auMobilePrefix.concat(this.finalModel.mobile);
        m.channel = "sms";
        m.locale = "en";
        //console.log(m.mobileNumber);

        // Twilio functions do not accept multipart/form-data
        const data = new URLSearchParams();
        //data.append("phone_number", m.mobileNumber);
        data.append("to", m.mobileNumber);
        data.append("channel", m.channel);
        data.append("locale", m.locale);

        // Create your own Twilio Function
        // https://www.twilio.com/console/functions/manage
        //fetch("https://verify-2896-dfs53r.twil.io/start-verify", {
        fetch(this.startVerify, {
          method: "POST",
          body: data,
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            if (json.success) {
              //console.log("Successfully sent token.");
              // this.firstVerifyInitiated = 1;
              this.txtDirty = true;
            } else {
              console.log(json.error);
            }
          })
          .catch((err) => {
            console.log(err);
            console.log("Error starting verification.");
          });
      }
    },
    //  isLastStep() {
    //    if (this.$refs.wizard) {
    //      return this.$refs.wizard.isLastStep;
    //    }
    //    return false;
    //  },

    validateAsync: function() {
      // check to see if the validation code matches any of these and if they do show an error
      if (
        this.vcode === "" ||
        this.vcode.length < 6 ||
        this.vcode.length > 6 ||
        isNaN(this.vcode)
      ) {
        return new Promise((resolve, reject) => {
          if (
            this.vcode === "" ||
            this.vcode.length < 6 ||
            this.vcode.length > 6
          ) {
            reject("You must provided a valid 6 digit code code");
          }
          if (isNaN(this.vcode)) {
            reject("You must provide numbers only");
          } else {
            resolve(true);
          }
        });
      } else {
        if (!this.validationcode) {
          this.verifyNumber();

          return new Promise((resolve, reject) => {
            setTimeout(() => {
              if (!this.validationcode) {
                reject("Verification code is incorrect. Please try again.");
              } else {
                resolve(true);
              }
            }, 1000);
          });
        }
      }
    },
  },
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* Hide the top bar tabs start */

.wizard-nav.wizard-nav-pills,
.wizard-progress-with-circle {
  display: none;
}
.wizard-header {
  display: none;
}

/* CSS to hide top nav bar*/

/* Hide the top bar tabs end */

label {
  font-size: 14px;
  float: left;
}

.help-block {
  color: red;
  font-size: 14px;
  float: left;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  /* display: inline-block; */
  margin: 0 10px;
}
a {
  color: #007bff;
}

span.error {
  color: #e74c3c;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

/* This is a css loader. It's not related to vue-form-wizard */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #007bff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .wizard-btn {
    width: 100%;
  }
}
</style>
