<template>
  <div class="container-fluid color-bond pt-5 pb-7">
    <div class="container color-bond p-3 pt-4">
      <h1 class="text-center pt-5 text-black head-current">Current job openings</h1>
      <p class="text-center text-black p-3">
        I quit my job working for Nike. Just couldn’t do it anymore.
      </p>
      <b-row>
        <b-col sm="2"></b-col>
        <b-col sm="8"><CurrentJobs /></b-col>
        <b-col sm="2"></b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CurrentJobs from "../components/CurrentJobs.vue";
export default {
  components: {
    CurrentJobs,
  },
};
</script>

<style scope>
.p-lg-5 {
  padding: 3rem !important;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.75rem;
  color: #c0ccda;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #2c4056;
  border-radius: 0.75rem;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  /* background-color: #2c4056; */
  background-clip: padding-box;
  /* border: 1px solid #223244; */
  border-radius: 0.375rem;
  box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075);
  transition: all 0.2s ease;
}
.custom-input-file-link + label {
  padding: 0;
  border: 0;
  background: 0 0;
  color: #0c66ff;
  font-size: 0.875rem;
  font-weight: 600;
}
.custom-input-file + label {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* border: 1px solid #223244; */
  border-radius: 0.375rem;
  color: #fff;
  background-color: #2c4056;
  outline: 0;
  margin: 0;
}
.custom-input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  outline: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.custom-input-file-link + label {
  padding: 0;
  border: 0;
  background: 0 0;
  color: #0c66ff;
  font-size: 0.875rem;
  font-weight: 600;
}
.form-control-label {
  color: #1a1a1a;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: none;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  float: none;
}
.color-bond {
  background-color: rgb(244, 244, 244);
  /* #1a1a1a; */
}
.my-btn {
  padding: 0.75rem 1rem !important;
}

.pb-7 {
  padding-bottom: 7rem !important;
}

@media screen and (max-width: 767px) {
  .head-current {
    font-size: 2rem;
  }
}
</style>
