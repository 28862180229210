<template>
  <div>
    <div>
      <b-button
        class="btn btn-primary btn-lg"
        variant="primary"
        block
        v-b-modal.download-modal
      >
        Claim eBook
      </b-button>

      <b-modal id="download-modal" centered hide-header hide-footer>
        <div class="card" style="border:none;">
          <div class="card-body">
            <b-form @submit.stop.prevent="onSubmit" v-if="!issubmitted">
              <b-form-group
                id="example-input-group-1"
                label="Name"
                label-for="example-input-1"
                class="inputa-label"
              >
                <b-form-input
                  id="example-input-1"
                  name="example-input-1"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  aria-describedby="input-1-live-feedback"
                  placeholder="Enter name"
                  class="inputa"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >This is a required field and must be at least 3
                  characters.</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                id="example-input-group-2"
                label="Email"
                label-for="example-input-2"
                class="inputa-label"
              >
                <b-form-input
                  id="example-input-2"
                  name="example-input-2"
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-2-live-feedback"
                  placeholder="Enter email"
                  class="inputa"
                ></b-form-input>

                <b-form-invalid-feedback id="input-2-live-feedback"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                id="example-input-group-3"
                label="Mobile"
                label-for="example-input-3"
                class="inputa-label"
              >
                <b-form-input
                  id="example-input-3"
                  name="example-input-3"
                  v-model="$v.form.mobile.$model"
                  :state="validateState('mobile')"
                  aria-describedby="input-3-live-feedback"
                  placeholder="Enter mobile"
                  class="inputa"
                ></b-form-input>

                <b-form-invalid-feedback id="input-3-live-feedback"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>


                <p>You should recieve the eBook via email within 5 minutes</p>
              <b-button
                type="submit"
                variant="primary"
                style="font-weight:600; padding: .8125rem 1.25rem"
                class="btn btn-primary btn-submitter"
                >Claim eBook</b-button
              >
            </b-form>
            <thankyou message="Thankyou, an email has been sent to you." mt="56px" v-if="issubmitted"></thankyou>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import thankyou from "../ThankYou";
// import store from "../store";

export default {
  // mounted() {
  //   store.isModalOpened = true;
  // },
  // beforeDestroy() {
  //   store.isModalOpened = false;
  // },
  mixins: [validationMixin],
  name: "Download",
  components: {
    thankyou,
  },
  data() {
    return {
      issubmitted: false,
      zapHook: "https://hooks.zapier.com/hooks/catch/7349379/oxhkj26/",
      form: {
        name: null,
        email: null,
        mobile: null,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
        // email: { required, email }
      },
      mobile: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(14),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      // check to see if there are any errors in the form
      // if there are show errors otherwise allow submission and change to thankyou view
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        this.issubmitted = true;

        axios
          .create({
            // eslint-disable-next-line no-unused-vars
            transformRequest: [(data, _headers) => JSON.stringify(data)],
          })
          .post(this.zapHook, this.form)
          .then(function(response) {
            console.log(response);
          });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.inputa {
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  height: calc(1.6em + 1.625rem + 2px);
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
}
.inputa-label {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  text-align: left;
}

.modal-contact {
  padding: 5em;
}

@media screen and (max-width: 767px) {
  .btn-contact {
    margin-top: 15px;
  }
  .btn-submitter {
    width: 100%;
  }
}
.btn-primary {
  color: #fff;
  /* background-color: #3755be;
  border-color: #3755be; */
  background-color:#FD163C;
}

.btn-primary:hover{
  background-color: #163CFD;
}

.btn {
  padding:18px;
  
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (max-width: 767px) {
  .btn {
    padding: 18px;
    font-size: 21px;
  }

}
</style>
