<template>
  <div>
    <div>
      <b-button
        class="btn btn-primary btn-lg"
        variant="primary"
        block
        v-b-modal.calendly-modal
      >
        Talk to an expert
      </b-button>

      <b-modal
        id="calendly-modal"
        title="BootstrapVue"
        centered
        hide-header
        hide-footer
      >
        <!-- Calendly inline widget begin -->
        <div
          class="calendly-inline-widget"
          data-url="https://calendly.com/skipil/30min"
          style="min-width:320px;height:630px;"
        ></div>

        <script
          type="application/javascript"
          defer
          src="https://assets.calendly.com/assets/external/widget.js"
        ></script>

        <!-- Calendly inline widget end -->
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendlyModal",
};
</script>

<style scoped>
.btn-primary {
  color: #fff;
  /* background-color: #3755be;
  border-color: #3755be; */
  background-color: #000;
  border-color: #000;
}
.btn-primary:hover{
  background-color: #163CFD;
}
.btn {
  padding: 18px;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (max-width: 767px) {
  .btn {
    padding: 18px;
    font-size: 21px;
  }
}
</style>
