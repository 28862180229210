import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
import Careers from "../components/Careers.vue";
import JuniorSystemsEngineer from "../components/Jobs/JuniorSystemsEngineer.vue";
import JuniorDeveloper from "../components/Jobs/JuniorDeveloper.vue";
import MarketingOfficer from "../components/Jobs/MarketingOfficer.vue";
import About from "../components/About.vue";
import Blog from "../components/Blog.vue";
import BlogPost from "../components/BlogPost.vue";
import PrivacyPolicy from "../components/PrivacyPolicy.vue";

import Branding from "../components/LeadPages/Branding.vue"
import BusinessChecker from "../components/LeadPages/BusinessChecker.vue"
import Insta from "../components/LeadPages/Insta.vue"
import RentalYieldCalculator from "../components/Tools/RentalYieldCalculator.vue"
RentalYieldCalculator

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/careers",
    name: "careers",
    component: Careers,
  },
  {
    path: "/careers/junior-systems-engineer",
    name: "junior-systems-engineer",
    component: JuniorSystemsEngineer,
  },
  {
    path: "/careers/junior-developer",
    name: "junior-developer",
    component: JuniorDeveloper,
  },
  {
    path: "/careers/marketing-officer",
    name: "marketing-officer",
    component: MarketingOfficer,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: '/blog/:slug',
    name: 'blog-post',
    component: BlogPost
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/branding",
    name: "branding",
    component: Branding,
  },
  {
    path: "/business-checker",
    name: "business-checker",
    component: BusinessChecker,
  },
  {
    path: "/insta",
    name: "insta-tree",
    component: Insta,
    meta: { hideNavigation: true }
  },
  {
    path: "/tools/RentalYieldCalculator",
    name: "rental-yield-calculator",
    component: RentalYieldCalculator,
    // meta: { hideNavigation: true }
  },
];



const router = new VueRouter({
  mode: 'history',
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    }
    return { x: 0, y: 0 };  // Go to the top of the page if no hash
  },
  
})

export default router;
