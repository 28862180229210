var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-navbar',{staticClass:"fixed-top",attrs:{"toggleable":"lg","type":"dark"}},[_c('div',{staticClass:"container"},[_c('router-link',{staticClass:"navbar-brand text-white",attrs:{"to":{
            path: '/',
          }}},[_vm._v("SKIPIL")]),_c('b-navbar-toggle',{staticClass:"text-white",attrs:{"target":"nav-collapse"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var expanded = ref.expanded;
return [(expanded)?_c('i',{staticStyle:{"font-style":"normal"}},[_c('i',{staticClass:"icon-cancel-1",staticStyle:{"margin-top":"2px"}})]):_c('i',[_vm._v("///")])]}}])}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"exact":"","to":{
                    path: '/',
                  }}},[_vm._v("Home")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/About","active":_vm.$route.name == 'about'}},[_vm._v("About")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                    path: '/',
                    hash: '#services',
                  }}},[_vm._v("Services")])],1),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-contact",modifiers:{"modal-contact":true}}],staticClass:"nav-link"},[_vm._v("Contact")])]),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/Careers","active":_vm.$route.name == 'careers'}},[_vm._v("Careers")])],1)])])],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }