<template>
  <div class="pt-5 mt-5 mb-5 head-spacer" style="">
    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-8">
              <h1 class="my-2">Privacy Policy</h1>

              <hr />
              <article class="article">
                <p class="lead">
                  Skipil takes your privacy seriously. We are committed to
                  ensuring your experience with our company is a positive one,
                  as such we ensure all possible measures are implemented to
                  keep your personal information private. The following
                  discloses our information gathering and dissemination
                  practices.
                </p>
                <h4>How we collect your information</h4>
                <p class="unlead">
                  We may obtain information about you in the following ways:
                </p>
                <h5>When you visit our website</h5>
                <p class="unlead">
                  We, like many companies, automatically collect the following
                  information:
                </p>
                <ul class="unlead">
                  <li>
                    Technical information including; the type of device you’re
                    using, IP address, browser and operating system being used
                    to connect your computer to the internet.
                  </li>
                  <li>
                    User behaviour, for example we collect information about
                    pages you visit and how you navigate the website, the length
                    of visits to certain pages, products and services you viewed
                    and searched for and referral sources
                  </li>
                  <li>
                    We collect and use your personal information by using
                    cookies on our website. We’ve included more information on
                    cookies below.
                  </li>
                </ul>
                <h5>Social Media</h5>
                <p class="unlead">
                  If you interact with us on our social media websites such as
                  Facebook, Twitter or Linkedin, then we may obtain information
                  about you, (for example, if you publically tag us in a photo).
                  The information we receive will depend on your privacy
                  settings for each social media platform.
                </p>
                <h5>Email</h5>
                <p class="unlead">
                  We may have information on hand such as your email address,
                  name, country and city If you subscribe to our mailing list,
                  perform an ecommerce transaction (if applicable) where you
                  enter your email or willingly share your email address and
                  details with us in any other way.
                </p>
                <h5>Googe Ads Remarketing Privacy Policy</h5>
                <!-- <p class="unlead"></p> -->
                <p class="unlead">
                  This website uses the Google Ads remarketing service to
                  advertise on third party websites (including Google) to
                  previous visitors to our site. It could mean that we advertise
                  to previous visitors who have completed a task on our site,
                  for example using the contact form to make an enquiry or
                  making a purchase.
                </p>
                <p class="unlead">
                  This could be in the form of an advertisement on the Google
                  search results page, or a site in the Google Display Network.
                  Third-party vendors, including Google, use cookies to serve
                  ads based on someone’s past visits to this website. Of course,
                  any data collected will be used in accordance with our own
                  privacy policy and Google’s privacy policy.
                </p>
                <p class="unlead">
                  You can set preferences for how Google advertises to you using
                  the
                  <a href="https://support.google.com/ads/answer/2662922?hl=en"
                    >Google Ad Preferences</a
                  >
                  page.
                </p>

                <h4>What type of information is collected from you?</h4>
                <p class="unlead">We may collect the following information:</p>
                <ul class="unlead">
                  <li>
                    Your name, job title and contact details (including postal
                    address, email address and telephone number, your date of
                    birth)
                  </li>
                  <li>
                    Information about your activities on our website, the device
                    used on our website, preferences and interests, your IP
                    address and geographical location
                  </li>
                  <li>
                    Other information relevant to customer surveys and/or offers
                  </li>
                </ul>

                <h4>Use of ‘cookies’</h4>
                <p class="unlead">
                  Like many other websites, this website uses cookies. ‘Cookies’
                  are small pieces of information sent by an organisation to
                  your computer and stored on your hard drive to allow that
                  website to recognise you when you visit. For example, we use
                  cookies to store your country preference. This helps us to
                  deliver a better more personalised service when you browse our
                  website and improve our services.
                </p>
                <p class="unlead">
                  It is possible to switch off cookies by setting your browser
                  preferences. Here are a few article that may help:
                </p>
                <p class="unlead">
                  <a
                    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
                    >Switching off cookies in chrome.</a
                  >
                </p>
                <p class="unlead">
                  <a
                    href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                    >Switching off cookies in Internet explorer.</a
                  >
                </p>
                <p class="unlead">
                  <a
                    href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US"
                    >Switching off cookies in Firefox.</a
                  >
                </p>
                <p class="unlead">
                  Please note, turning cookies off may result in a loss of
                  functionality when using our website.
                </p>

                <h4>How and why is your information used?</h4>
                <p class="unlead">
                  We require this information to understand your preferences and
                  provide you with a better service, and may be used for the
                  following reasons:
                </p>
                <ul class="unlead">
                  <li>
                    providing you with the services, products or information you
                    asked for.
                  </li>
                  <li>
                    carrying out our obligations under any contracts entered
                    into between you and us;
                  </li>
                  <li>keeping a record of your relationship with us;</li>
                  <li>
                    conducting analysis and market research so we can understand
                    how we can improve our services, products or information;
                  </li>
                  <li>
                    checking for updated contact details against third party
                    sources so we can stay in touch if you move;
                  </li>
                  <li>
                    seeking your views or comments on the services we provide;
                  </li>
                  <li>notifying you of changes to our services;</li>
                  <li>
                    sending you communications which you have requested and that
                    may be of interest to you. These may include information
                    about campaigns, fundraising appeals and activities and
                    promotions of goods and services; and
                  </li>
                  <li>processing job applications.</li>
                </ul>
                <h4>How long is your information kept for?</h4>
                <p class="unlead">
                  We keep your information for no longer than is necessary for
                  the purposes it was collected for. The length of time we
                  retain your personal information for is determined by
                  operational and legal considerations. For example, we are
                  legally required to hold some types of information to fulfil
                  our statutory and regulatory obligations.
                </p>
                <h4>Who has access to your information?</h4>

                <p class="unlead">
                  W We do not sell or rent your information to third parties.
                </p>
                <p class="unlead">
                  We do not share your information with third parties for
                  marketing purposes.
                </p>
                <p class="unlead">
                  Whowever, we may disclose your information to third parties in
                  order to achieve the other purposes set out in this policy.
                  Third parties working on our behalf include: suppliers,
                  agents, subcontractors and other associated organisations for
                  the purposes of completing tasks and providing services to you
                  on our behalf (for example to process orders and send you
                  mailings).
                </p>
                <p class="unlead">
                  When we use these third parties, we disclose only the personal
                  information that is necessary to deliver the services and we
                  have a contract in place that requires them to keep your
                  information secure and prevents them from using it for their
                  own direct marketing purposes.
                </p>
                <p class="unlead">
                  Please be reassured that we will not release your information
                  to third parties for them to use for their own direct
                  marketing purposes, unless you have requested us to do so, or
                  we are required to do so by law, for example, by a court order
                  or for the purposes of prevention of fraud or other crime.
                </p>

                <h4>Controlling your personal information</h4>
                <p class="unlead">
                  You have a choice about whether or not you wish to receive
                  information from us. If you do not want to receive direct
                  marketing communications from us then you can select your
                  choices by ticking the relevant boxes situated on the form
                  used to collect your information.
                </p>
                <p class="unlead">
                  We’re committed to putting you in control of your data so
                  you’re free to change your marketing preferences (including to
                  tell us that you don’t want to be contacted for marketing
                  purposes) at any time by contacting us by email:
                  <a href="mailto:hello@skipil.com">hello@skipil.com</a> 
                  <!-- phone (02) 7182 0122 or post. -->
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacypolicy",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.unlead {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}
.article p.lead:first-child {
  margin-top: 4.5rem;
  margin-bottom: 3rem;
}
hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
figure {
  text-align: center;
  margin: 3rem 0;
}

div.rounded,
img.rounded {
  border-radius: 0.625rem !important;
}

figcaption {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #adb5bd;
}

img {
  max-width: 100%;
}
.article p + h4 {
  margin-top: 3rem;
}
.article p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.text-muted {
  color: #adb5bd !important;
}
.btn-primary {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;

  user-select: none;

  border: 1px solid transparent;
  padding: 0.4375rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.location {
  margin: 0;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background-color: #fff;
}

ul {
  list-style: circle;
  margin-left: 20px;
  margin-bottom: 50px;
}
li {
  display: list-item;
}

@media screen and (max-width: 767px) {
  .head-spacer {
    padding-top: 1.5rem !important;
  }

  .sa {
    margin-left: 15px;
  }
}
</style>
