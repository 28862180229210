<template style="background-color:#f8fbfd">
  <div class="container mt-9 mb-5">
    <section class="pt-8 pt-md-11">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-9 col-xl-8">
            <!-- Heading -->
            <h1 class="display-4 text-center">
              {{ title }}
            </h1>

            <!-- Text -->
            <p class="lead mb-7 text-center text-muted">
              {{ summary }}
            </p>

            <!-- Meta -->
            <div class="row align-items-center py-5 border-top border-bottom">
              <div class="col-auto">
                <!-- Avatar -->
                <!-- <div class="avatar avatar-lg">
                  <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle">
                </div> -->
              </div>
              <div class="col">
                <!-- Name -->
                <h6 class="text-uppercase mb-0">
                  SKIPIL
                </h6>

                <!-- Date -->
                <time class="font-size-sm text-muted" datetime="2019-05-20">
                  Published on {{ date }}
                </time>
              </div>
              <div class="col-auto">
                <!-- Share -->
                <span
                  class="h6 text-uppercase text-muted d-none d-md-inline mr-4"
                >
                  5 MIN READ
                </span>

                <!-- Icons -->
                <!-- <li class="list-inline-item list-social-item mr-3">
                  <a
                    href="#!"
                    class="text-decoration-none fab fa-instagram social-iconi"
                  >
                  </a>
                </li> -->
                <!-- <ul class="d-inline list-unstyled list-inline list-social">
                  <li class="list-inline-item list-social-item mr-3">
                    <a
                      v-bind:href="this.facebookShare"
                      class="text-decoration-none fab fa-facebook-f social-iconi"
                    >
                    </a>
                  </li>
                  <li class="list-inline-item list-social-item mr-3">
                    <a
                      v-bind:href="this.twitterShare"
                      class="text-decoration-none fab fa-twitter social-iconi"
                    >
                    </a>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <section class="pt-6 pt-md-8">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-9 col-xl-8">
            <!-- Fugure -->
            <figure class="figure mb-7">
              <!-- Image -->

              <img
                v-bind:src="this.featureimg"
                class="figure-img img-fluid rounded lift lift-lg"
                v-bind:alt="featureimgalt"
              />

              <!-- Caption -->
              <!-- <figcaption class="figure-caption text-center">
                This is a caption on this photo for reference
              </figcaption> -->
            </figure>

            <div class="content" v-html="body"></div>

            <b-row>
              <b-col sm="4">
                <CalendlyModal />
              </b-col>
              <b-col sm="4"></b-col>
            </b-row>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>
  </div>
</template>

<script>
import { butter } from "@/buttercms";
import CalendlyModal from "../components/CalendlyModal";

export default {
  name: "blog-post",
  components: { CalendlyModal },

  data() {
    return {
      post: {},
      title: "",
      body: "",
      featureimg: "",
      featureimgalt: "",
      summary: "",
      date: "",
      facebookShare: "",
      twitterShare: "",
      // posts: [],
      // categories: [],
    };
  },
  methods: {
    getPost() {
      butter.post
        .retrieve(this.$route.params.slug)
        .then((res) => {
          console.log(res.data);
          this.post = res.data;
          this.title = this.post.data.title;
          this.body = this.post.data.body;
          this.featureimg = this.post.data.featured_image;
          this.featureimgalt = this.post.data.featured_image_alt;
          this.summary = this.post.data.summary;

          this.date = this.post.data.published;

          console.log(this.date);

          // Create date object from datetime string
          var date = new Date(this.date);

          // Coverting to local datetime
          //console.log(date.toDateString());

          this.date = date
            .toDateString()
            .split(" ")
            .slice(1)
            .join(" ");

          var currentUrl = window.location.href;
          this.facebookShare =
            "https://www.facebook.com/sharer/sharer.php?u=" + currentUrl;
          console.log(this.facebookShare);

          this.twitterShare =
            "https://twitter.com/intent/tweet?url=" +
            currentUrl +
            "&via=getboldify&text=yourtext";
          console.log(this.twitterShare);
        })

        .catch((res) => {
          console.log(res);
        });
    },
    // getPosts() {
    //   butter.post
    //     .list({
    //       page: 1,
    //       page_size: 10,
    //     })
    //     .then((res) => {
    //       // console.log(res.data)
    //       this.posts = res.data.data;
    //     });
    // },
    // getCategories() {
    //   butter.category.list().then((res) => {
    //     console.log("List of Categories:");
    //     console.log(res.data.data);
    //   });
    // },
    // getPostsByCategory() {
    //   butter.category
    //     .retrieve("example-category", {
    //       include: "recent_posts",
    //     })
    //     .then((res) => {
    //       console.log("Posts with specific category:");
    //       console.log(res);
    //     });
    // },
  },
  created() {
    this.getPost();
    // this.getPosts();
    // this.getCategories();
    // this.getPostsByCategory();
  },
};
</script>

<style scoped>
.mt-9 {
  margin-top: 9rem;
}
.widget-title::before {
  position: absolute;
  left: 0;
  top: 3px;
  height: 2%;
  width: 4px;
  background: #fd173d;
  content: "";
}
div.rounded,
img.rounded {
  border-radius: 0.625rem !important;
}
.pt-md-8,
.py-md-8 {
  padding-top: 3rem !important;
}
.text-muted {
  color: #869ab8 !important;
}
.border-bottom {
  border-bottom: 1px solid #f1f4f8 !important;
}
.border-top {
  border-top: 1px solid #f1f4f8 !important;
}

.social-iconi {
  font-size: 20px;
  color: #869ab8;
}

@media screen and (max-width: 767px) {
  .display-4 {
    font-size: 2rem;
  }
  .mt-9 {
    margin-top: 6rem;
  }
  .lead {
    font-size: 1rem;
  }
}
</style>
