<template>
<div>
              <b-row>
                <b-col sm="12">
                  <div class="card contact-card mb-0">
                    <div class="card-body p-0">
                      <b-form
                        @submit.stop.prevent="onSubmit"
                        v-if="!issubmitted"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <b-form-group
                              id="example-input-group-1"
                              label="First Name"
                              label-for="example-input-1"
                              class="form-control-label"
                            >
                              <b-form-input
                                id="example-input-1"
                                name="example-input-1"
                                v-model="$v.form.firstname.$model"
                                :state="validateState('firstname')"
                                aria-describedby="input-1-live-feedback"
                                placeholder="Enter your first name"
                                class="inputa"
                              ></b-form-input>

                              <b-form-invalid-feedback
                                id="input-1-live-feedback"
                                >This is a required field and must be at least 3
                                characters.</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              id="example-input-group-1"
                              label="Last Name"
                              label-for="example-input-1"
                              class="form-control-label"
                            >
                              <b-form-input
                                id="example-input-1"
                                name="example-input-1"
                                v-model="$v.form.lastname.$model"
                                :state="validateState('lastname')"
                                aria-describedby="input-1-live-feedback"
                                placeholder="Also your last name"
                                class="inputa"
                              ></b-form-input>

                              <b-form-invalid-feedback
                                id="input-1-live-feedback"
                                >This is a required field and must be at least 3
                                characters.</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </div>
                        </div>

                        <div class="row align-items-center">
                          <div class="col-md-6">
                            <b-form-group
                              id="example-input-group-2"
                              label="Email"
                              label-for="example-input-2"
                              class="form-control-label"
                            >
                              <b-form-input
                                id="example-input-2"
                                name="example-input-2"
                                v-model="$v.form.email.$model"
                                :state="validateState('email')"
                                aria-describedby="input-2-live-feedback"
                                placeholder="Enter email"
                                class="inputa"
                              ></b-form-input>

                              <b-form-invalid-feedback
                                id="input-2-live-feedback"
                                >This is a required
                                field.</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </div>

                          <div class="col-md-6">
                            <b-form-group
                              id="example-input-group-2"
                              label="Mobile"
                              label-for="example-input-2"
                              class="form-control-label"
                            >
                              <b-form-input
                                id="example-input-2"
                                name="example-input-2"
                                v-model="$v.form.mobile.$model"
                                :state="validateState('mobile')"
                                aria-describedby="input-2-live-feedback"
                                placeholder="Enter mobile"
                                class="inputa"
                              ></b-form-input>

                              <b-form-invalid-feedback
                                id="input-2-live-feedback"
                                >This is a required
                                field.</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label"
                                >Resume/CV</label
                              >
                              <div>
                                <b-form-file
                                  v-model="$v.form.resume.$model"
                                  :state="validateState('resume')"
                                  @change="attachItem"
                                  accept=".pdf, .doc, .docx"
                                  class=""
                                  placeholder="Attach your file"
                                ></b-form-file>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              id="example-input-group-1"
                              label="Online resume / LinkedIn"
                              label-for="example-input-1"
                              class="form-control-label"
                            >
                              <b-form-input
                                id="example-input-1"
                                name="example-input-1"
                                v-model="$v.form.onlineresume.$model"
                                :state="validateState('onlineresume')"
                                aria-describedby="input-1-live-feedback"
                                placeholder=""
                                class="inputa"
                              ></b-form-input>

                              <b-form-invalid-feedback
                                id="input-1-live-feedback"
                              ></b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <b-form-group
                              id="example-input-group-2"
                              label="Information"
                              label-for="example-input-2"
                              class="form-control-label"
                            >
                              <b-form-textarea
                                id="example-input-2"
                                name="example-input-2"
                                v-model="$v.form.information.$model"
                                :state="validateState('information')"
                                aria-describedby="input-2-live-feedback"
                                placeholder="Tell us something interesting"
                                rows="3"
                              ></b-form-textarea>

                              <b-form-invalid-feedback
                                id="input-2-live-feedback"
                              ></b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="mt-3">
                          <b-button
                            type="submit"
                            variant="primary"
                            style="font-weight:600; padding: .8125rem 1.25rem"
                            class="btn btn-primary"
                            >Submit your application</b-button
                          >
                        </div>
                      </b-form>
                      <thankyou message="Thank you. Your application has been recieved." mt="67px" v-if="issubmitted"></thankyou>

                      <!-- <div >
      <input type="file" @change="previewImage" accept=".doc, .docx,.pdf" >  
    </div> -->
                      <!-- <button @click="onUpload">Upload</button> -->
                    </div>
                  </div></b-col
                >
              </b-row>

  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';
import axios from "axios";
import { validationMixin } from "vuelidate";
import {required, minLength, numeric, email} from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
import thankyou from "../../components/ThankYou";

const mob = helpers.regex("mob", /^\D*(?:04(?:\D*\d){8}|4(?:\D*\d){8})\D*$/);

export default {
  props: ['job'],
  mixins: [validationMixin],
  name: "SubmissionForm",
  components: {
    thankyou,
  },
  data() {
    return {
      position: this.job,
      filename: "",
      resumeData: null,
      file: "",
      issubmitted: false,
      timestamp: "",
      bucket: "https://storage.googleapis.com/ski-bucket",
      zapHook: "https://hooks.zapier.com/hooks/catch/7349379/oeamczo/",
      form: {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
        information: null,
        resume: null,
        onlineresume: null,
       // position: "Systems Engineer"
      },
    };
  },
  validations: {
    form: {
      firstname: {
        required,
        minLength: minLength(3),
      },
      lastname: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      mobile: {
        required,
        numeric,
        mob,
      },
      onlineresume: {
        // not required
      },
      resume: {
        // not reuquired
        required
      },
      information: {
        // not required
      },
    },
  },
  methods: {
    attachItem(event) {
      this.resumeData = event.target.files[0];
    },
    onUpload() {
      const firstName = this.form.firstname;
      const lastName = this.form.lastname;
      const posi = this.position;

      const uninum = Math.floor(
        Math.random() * Math.floor(Math.random() * Date.now())
      );

      //const filename = firstName + " " + lastName + " " + posi + " - " + uninum;

      this.filename = firstName + " " + lastName + " - " + posi + " - " + uninum;

      firebase
        .storage()
        .ref(`${this.filename}`)
        .put(this.resumeData);
        
      //firebase.storage().ref(`${this.data.resume.name}`).put(this.data.resume);
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        this.issubmitted = true;

        if (this.form.resume) {
          this.onUpload();
        }


let date_ob = new Date();

// adjust 0 before single digit date
let date = ("0" + date_ob.getDate()).slice(-2);

// current month
let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

// current year
let year = date_ob.getFullYear();

// current hours
let hours = date_ob.getHours();

// current minutes
let minutes = date_ob.getMinutes();

// current seconds
let seconds = date_ob.getSeconds();

// prints date & time in DD-MM-YYYY HH:MM:SS format
this.timestamp = (date + "-" + month + "-" + year + " " + hours + ":" + minutes + ":" + seconds);
      
      // add two values to form object
      this.form.filename = this.filename;
      this.form.position = this.position;
      this.form.timestamp = this.timestamp

      // remove resume value from form object
      this.$delete(this.form, 'resume')

         // eslint-disable-next-line no-unused-vars
         axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
          .post(this.zapHook, this.form)
          .then(function(response) {
            console.log(response);
          });

        // axios
        //   .post(this.zapHook, this.form, {
        //     headers: { Accept: "application/json" },
        //   })
        //   .then(function(response) {
        //     console.log(response);
        //   });
      }
    },
  },
};
</script>

<style scoped>

.btn-primary {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.4375rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (max-width: 767px) {
  .btn {
    width: 100%;
  }
}

.contact-card {
  border: none;
}

</style>
