<template>
  <div>
    <div class="form-group" v-bind:class="{ 'has-error': $v.fullName.$error }">
      <label class="inputa-label">Full name</label>
      <input
        class="form-control inputa"
        :class="{ 'is-invalid': $v.fullName.$error }"
        v-model.trim="fullName"
        @input="$v.fullName.$touch()"
        placeholder="Enter your name"
      />

      <span
        class="help-block"
        v-if="$v.fullName.$error && !$v.fullName.required"
        >Full name is required</span
      >
    </div>

    <div
      class="form-group mt-4"
      v-bind:class="{ 'has-error': $v.email.$error }"
    >
      <label class="inputa-label">Email</label>
      <input
        class="form-control inputa"
        :class="{ 'is-invalid': $v.email.$error }"
        v-model.trim="email"
        @input="$v.email.$touch()"
        placeholder="Enter your email"
      />
      <span class="help-block" v-if="$v.email.$error && !$v.email.required"
        >Email is required</span
      >
      <span class="help-block" v-if="$v.email.$error && !$v.email.email"
        >This is not a valid email!</span
      >
    </div>

    <div
      class="form-group mt-4"
      v-bind:class="{ 'has-error': $v.mobile.$error }"
    >
      <label class="inputa-label" style="float: none;">Mobile</label><br />
      <div class="input-group-prepend mt-2">
        <span class="input-group-text" id="basic-addon1">+61</span>
        <input
          class="form-control mob-inputa"
          :class="{ 'is-invalid': $v.mobile.$error }"
          v-model.trim="mobile"
          @input="$v.mobile.$touch()"
          placeholder="Enter your mobile"
        />
      </div>

      <span
        class="help-block"
        v-if="
          ($v.mobile.$error && !$v.mobile.numeric) ||
            ($v.mobile.$error && !$v.mobile.validAusMobile) ||
            ($v.mobile.$error && !$v.mobile.required)
        "
        >We require a valid Australian mobile number</span
      >
    </div>

    <br />
    <p class="mutedText mb-0 text-left">
      <small
        >We will never sell your personal details to anyone. Ever.<br />
        By registering you agree to the Skipil
        <router-link to="/privacy-policy" class="">Privacy Policy</router-link> and to receive
        emails.
      </small>
    </p>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
import { numeric } from "vuelidate/lib/validators";

// custom regular expressions to check if either of them equals true with or from vuelidate. Should match like 0412 123 123 or 4 12 123 123

const mob = helpers.regex("mob", /^\D*(?:04(?:\D*\d){8}|4(?:\D*\d){8})\D*$/);

export default {
  name: "step1",
  components: {},
  data() {
    return {
      fullName: "",
      email: "",
      mobile: "",
    };
  },
  validations: {
    fullName: {
      required,
    },
    email: {
      required,
      email,
    },
    mobile: {
      required,
      numeric,
      mob,
    },
    form: ["fullName", "email", "mobile"],
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },
  },
};
</script>

<style scoped>
/* Mobile addon front start */
#basic-addon1 {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e9ecef
}
.mob-inputa {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  height: calc(1.6em + 1.625rem + 2px);
  border: 1px solid #e9ecef
}
/* Mobile addon front end */

.inputa {
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  height: calc(1.6em + 1.625rem + 2px);
  border: 1px solid #e9ecef;
  border-radius: 0.375rem;
}
.inputa-label {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  text-align: left;
}
</style>
