<template>
  <!-- Page Content -->
  <section class="py-5 pb-7" style="background-color:#F4F4F4;">
   
    <div class="container mt-5">
      <b-row class="">
        <b-col sm="6" class="text-center my-auto">
          <h3 class="mb-4 text-lg-left text-center">
            About - The page you probably won't read
          </h3>
          <p class="text-lg" style="text-align: justify;">
            Lets get straight to the point. If we were like every other digital
            agency we would pitch you with some bs waffle. Truth is we work with
            companies to do things they can't otherwise do themselves. Why waste
            the time doing something you hate when you can pay us to do
            something we love for the thing you love?
          </p>
        </b-col>
        <b-col sm="6" class="mt-2 mobile-space">
          <img
            src="../assets/info-1.webp"
            width="320"
            height="320"
            alt="Italian Trulli"
            class="img-fluid float-right"
          />
        </b-col>
      </b-row>
    </div>
  </section>

  <!-- <div class="container mt-5">
        <h2 class="pt-5">About</h2> -->

  <!-- <p>Lets get straight to the point. If we were like every other digital agency we would pitch you with some bs waffle. Truth is we work with companies to do things they can't otherwise do themselves. Why waste the time doing something you hate when you can pay us to do something we love for the thing you love?</p> -->
  <!-- </div> -->
</template>

<script>
export default {};
</script>

<style scoped>
.pb-7 {
  padding-bottom: 7rem !important;
}
@media screen and (max-width: 767px) {
  .img-fluid {
    margin:15px;
  }
}
</style>
