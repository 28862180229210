<template>
  <div>
    <!-- Full Page Image Header with Vertically Centered Content -->
    <header class="masthead" id="home">
      <div class="container h-100">
        <div class="row h-100 align-items-center">
          <div class="col-12 text-center">
            <b-container class="bv-example-row">
              <b-row>
                <b-col sm="" class="text-left">
                  <h1 class="display-2 text-white mb-0">
                    Quality leads at your fingertips
                  </h1></b-col
                >
                <b-col sm="2"></b-col>
                <b-col sm="" class="text-left m-auto">
                  <hr />
                  <p class="lead font-weight-normal text-white">
                    Over 10 years of combined experience - so we know a thing or two about building websites that actually generate leads.
                  </p>

                  <b-button
                    class="btn btn-lg btn-secondary call-to-action-btn mt-4"
                    v-b-modal.modal-center
                    >skip the queue</b-button
                  >

                  <b-modal id="modal-center" centered hide-header hide-footer>
                    <MultiStepVerification />
                    <p class="my-4"></p>
                  </b-modal>
                </b-col>
              </b-row>
              <span id="services"></span>
            </b-container>
          </div>
        </div>
      </div>
    </header>

    <!-- Page Content -->
    <section class="py-5">
      <div class="container">
        <h2 class="mb-5 text-center" style="font-weight:500px">Services</h2>
        <p class="m-5 text-center service-text">
          While our roots are in the automation and development space, we have
          evolved into a full-service digital agency.
        </p>
        <b-row>
          <b-col sm="4" class="mt-2">
            <b-button squared block variant="primary" class="serv-btns"
              ><h5>Email Marketing</h5></b-button
            >
          </b-col>
          <b-col sm="4" class="mt-2">
            <b-button squared block variant="primary" class="serv-btns"
              ><h5>UX Design</h5></b-button
            >
          </b-col>
          <b-col sm="4" class="mt-2">
            <b-button squared block variant="primary" class="serv-btns"
              ><h5>Product Design</h5></b-button
            >
          </b-col>
        </b-row>
        <b-row class="">
          <b-col sm="4" class="mt-2">
            <b-button squared block variant="primary" class="serv-btns"
              ><h5>Media Planning</h5></b-button
            >
          </b-col>
          <b-col sm="4" class="mt-2">
            <b-button squared block variant="primary" class="serv-btns"
              ><h5>Brand Idenity</h5></b-button
            >
          </b-col>
          <b-col sm="4" class="mt-2">
            <b-button squared block variant="primary" class="serv-btns"
              ><h5>Social Media</h5></b-button
            >
          </b-col>
        </b-row>
      </div>
    </section>

    <!-- Page Content -->
    <section class="py-5" style="background-color:#F4F4F4;">
      <div class="container">
        <b-row class="">
          <b-col sm="6" class="text-center my-auto">
            <h3 class="mb-4 text-lg-left text-center">
              Building solutions to keep buisness's in buisness
            </h3>
            <p class="text-lg-left text-center">
              Skipil builds innovative and automates trivial process’s so your 
              team can focus on generating business.
            </p>
          </b-col>
          <b-col sm="6" class="mt-2 mobile-space">
            <img
              src="../assets/Feature-item.webp"
              width="544"
              height="368"
              alt="Italian Trulli"
              class="img-fluid"
            />
          </b-col>
        </b-row>
      </div>
    </section>

    <b-row class="m-0">
      <a
        v-b-modal.modal-center
        class="col-sm-6"
        style="background-color:#000000; text-decoration:none;"
        ><h2 class="ribbon-btns text-lg-left text-center">
          START YOUR PROJECT
        </h2></a
      >

      <router-link
        to="/Careers"
        class="col-sm-6"
        style="background-color:#262161; text-decoration:none;"
      >
        <h2 class="ribbon-btns text-lg-left text-center">
          WANNA JOIN OUR TEAM?
        </h2></router-link
      >
    </b-row>

       





  </div>
</template>

<script>
import MultiStepVerification from "./Multistep/Multi";

export default {
  name: "Cover",
  components: {
    MultiStepVerification,
  },
  methods: {},
};
</script>

<style lang="css" scoped>
.masthead {
  height: 100vh;
  min-height: 00px;
  /* background-image: url("https://source.unsplash.com/BtbjCFUvBXs/1920x1080"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000000;
}
.call-to-action-btn {
  color: #000;
  background-color: #ffffff;
}

.serv-btns {
  height: 100px;
  /* background-color: #262161;
  border-color: #262161; */

  background-color: #e1e1e1;
  color: #000000;
  border-color: #e1e1e1;
}
.serv-btns:hover {
  background-color: #262161;
  border-color: #262161;
  color: #ffffff;
}

h5 {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.ribbon-btns {
  color: #ffffff;
  font-size: 40px;
  font-weight: 900;
  padding: 5% 20% 5% 20%;
}
hr {
  margin-top: 1rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 3px solid #ffffff;
}

@media screen and (max-width: 767px) {
  .mobile-space {
    margin-right: 25px;
    text-align: center;
  }
  h1 {
    font-size: 2.5em;
  }
  .masthead {
    height: 80vh;
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #ffffff;
    width: 100px;
    margin-right: 500px;
  }

  .ribbon-btns {
    font-size: 30px;
  }

  .service-text{
    margin: 1rem !important;
  }
}


</style>
