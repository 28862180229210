<template>
  <div>
    <div
      class="form-group"
      v-bind:class="{ 'has-error': $v.buisiness.$error }"
    >
      <label class="inputa-label" for="buisiness">Do you have an established business?</label>
      <select class="form-control custom-select-lg" 
      v-model="buisiness" 
      :class="{'is-invalid' : $v.buisiness.$error }"
      >
        <option value="" disabled>Select...</option>
        <option>Yes</option>
        <option>No</option>
      </select>

      <span
        class="help-block"
        v-if="$v.buisiness.$error && !$v.buisiness.required"
        >Buisness status is required</span
      >
    </div>
    <div class="form-group mt-5" v-bind:class="{ 'has-error': $v.website.$error }">
      <label class="inputa-label" for="website"
        >Do you have an existing website?</label
      >
      <select class="form-control custom-select-lg" v-model="website" :class="{'is-invalid' : $v.website.$error }">
        <option value="" disabled>Select...</option>
        <option>Yes</option>
        <option>No</option>
      </select>
 
      <span
        class="help-block"
        v-if="$v.website.$error && !$v.website.required"
        >Website is required</span
      >
    </div>

    <div class="form-group mt-5" v-bind:class="{ 'has-error': $v.lookingfor.$error }">
      <label class="inputa-label" for="lookingfor">What is your main focus?</label>
       <select class="form-control custom-select-lg" v-model="lookingfor" :class="{'is-invalid' : $v.lookingfor.$error }">
        <option value="" disabled>Select...</option>
        <option>A new website</option>
        <option>To generate better leads</option>
        <option>Website SEO</option>
        <option>Task Automation</option>
        <option>Something else</option>
      </select>
      <span
        class="help-block"
        v-if="$v.lookingfor.$error && !$v.lookingfor.required"
        >Please select an option</span
      >
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "step3",
  components: {},
  data() {
    return {
      buisiness: '',
      website: '',
      lookingfor: '',
    };
  },
  validations: {
    buisiness: {
      required,
    },
    website: {
      required,
    },
    lookingfor: {
      required,
    },
    form: ["buisiness", "website", "lookingfor"],
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },
  },
};
</script>

<style scoped>
.is-invalid{
    background-position: right calc(1.375em + 0.1875rem) center !important;
}
.inputa {
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  height: calc(1.6em + 1.625rem + 2px);
  border: 1px solid #e9ecef;
  border-radius: 0.375rem;
}
.inputa-label {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  text-align: left;
}
</style>
