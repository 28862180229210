import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import App from './App.vue'
import router from "./router/index"
import firebase from 'firebase/app'
import 'firebase/storage';



import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Import the styles directly. (Or you could add them via script tags.)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Butter from 'buttercms'
export const butter = Butter('07aa189581aa13a3dfda1b62a5635b5b5ce99bd0')

Vue.config.productionTip = false
Vue.use(BootstrapVue);

firebase.initializeApp({
  apiKey: "AIzaSyAl7tZNvPnUrF7mzwf69OaYkyeaKfg1pUw",
    authDomain: "skipil-website.firebaseapp.com",
    databaseURL: "https://skipil-website.firebaseio.com",
    projectId: "skipil-website",
    storageBucket: "skipil-website.appspot.com",
    messagingSenderId: "288008355799",
    appId: "1:288008355799:web:e900b51bfc733596b6d592",
    measurementId: "G-KEVL7V7255"
  });
  

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
