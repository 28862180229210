<template>
  <div>
    <!-- <b-row> -->
    <!-- <b-col sm="2"></b-col> -->
    <!-- <b-col sm="8"> -->

    <b-row>
      <b-col sm="1"></b-col>
      <b-col sm="10">
        <div v-for="job in jobs" v-bind:key="job.id">
          <router-link
            v-bind:to="job.url"
            class="card card-frame py-3 px-4 mb-2 router-link"
          >
            <div class="row align-items-sm-center">
              <span class="col-sm-6 text-dark">
                {{ job.title }}
              </span>
              <span class="col-sm-3 text-body">
                {{ job.location }}
              </span>
              <span class="col-md-3 text-left apply-link">
                Apply <i class="fas fa-angle-right fa-sm ml-1"></i>
              </span>
            </div>
          </router-link>
        </div>
      </b-col>
      <b-col sm="1"></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CurrentJobs",
  data() {
    return {
      jobs: [
        {
          id: 1,
          title: "Junior Developer",
          location: "Sydney",
          url: "/careers/junior-developer",
        },
        {
          id: 2,
          title: "Marketing Officer",
          location: "Sydney",
          url: "/careers/marketing-officer",
        },
        {
          id: 3,
          title: "Junior Systems Engineer",
          location: "Sydney",
          url: "/careers/junior-systems-engineer",
        },
      ],
    };
  },
};
</script>

<style scoped>
.card-frame {
  border: 0.0625rem solid #e7eaf3;
  box-shadow: none;
  transition: 0.3s;
  text-decoration: none;
}
.text-dark {
  color: #1e2022 !important;
}
.router-link {
  color: #377dff;
  text-decoration: none;
}

.card-frame-highlighted,
.card-frame:hover {
  border-color: rgba(55, 125, 255, 0.7);
}

@media screen and (max-width: 767px) {
  .apply-link {
    text-align: right !important;
    top: -32px;
  }
  .router-link {
    padding-bottom: 0px !important;
  }
}
</style>
