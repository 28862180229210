<template>
  <main class="app">
    <Navigation v-if="!$route.meta.hideNavigation" />
    <router-view style="min-height: 83vh;"></router-view>
    <Footer />
    <ContactModal />
  </main>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";
import ContactModal from "./components/ContactModal.vue";
export default {
  components: {
    Navigation,
    Footer,
    ContactModal,
  },
};
</script>
<style>

</style>
