<template>
  <div>
    <div class="container mt-9 mb-5">
      <section class="pt-8 pt-md-11">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <b-container class="bv-example-row">
                <b-row>
                  <b-col sm="6" class="col-img">
                    <img
                      src="../../assets/branding-eBook-image.png"
                      class="ml-5 download-img"
                      alt=""
                    />
                  </b-col>
                  <b-col sm="6" class="mt-5 pt-5 info-col">
                    <h1>From zero to fully informed in 30 minutes</h1>
                    <p>Build your own branding guidelines.</p>
                    <p>
                      Get started and grow your business the right way by downloading our free branding guidelines eBook:
                    </p>
                    <b-row class="mt-5">
                      <b-col sm="6">
                        <Download />
                      </b-col>
                      <b-col sm="6"> <CalendlyModal class="mtm-1"/></b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <!-- Meta -->
              <div
                class="row align-items-center py-5 mt-5 border-top border-bottom"
              >
                <div class="col-auto"></div>
                <div class="col">
                  <!-- Name -->
                  <h6 class="text-uppercase mb-0">
                    SKIPIL
                  </h6>

                  <!-- Date -->
                  <time class="font-size-sm text-muted" datetime="2019-05-20">
                    Published on
                  </time>
                </div>
                <div class="col-auto">
                  <!-- Share -->
                  <span
                    class="h6 text-uppercase text-muted d-none d-md-inline mr-4"
                  >
                    30 MIN EXERCISE
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- / .row -->
        </div>
        <!-- / .container -->
      </section>
    </div>
  </div>
</template>

<script>
import CalendlyModal from "../CalendlyModal.vue";
import Download from "../Modals/Download.vue";
export default {
  name: "branding",
  components: { CalendlyModal, Download },
};
</script>

<style scoped>

.mt-9 {
  margin-top: 9rem;
}
.widget-title::before {
  position: absolute;
  left: 0;
  top: 3px;
  height: 2%;
  width: 4px;
  background: #fd173d;
  content: "";
}
div.rounded,
img.rounded {
  border-radius: 0.625rem !important;
}
.pt-md-8,
.py-md-8 {
  padding-top: 3rem !important;
}
.text-muted {
  color: #869ab8 !important;
}
.border-bottom {
  border-bottom: 1px solid #f1f4f8 !important;
}
.border-top {
  border-top: 1px solid #f1f4f8 !important;
}

.social-iconi {
  font-size: 20px;
  color: #869ab8;
}

@media screen and (max-width: 767px) {
  .display-4 {
    font-size: 2rem;
  }
  .mt-9 {
    margin-top: 6rem;
  }
  .lead {
    font-size: 1rem;
  }
  .btn {
    padding: 18px;
    font-size: 21px;
  }
  .info-col {
    margin-right: 0 !important;
    margin-top: 20px !important;
  }
  .download-img {
    width: 340px;
    margin-left: 0 !important;
    padding-right: 20px !important;
    
  }
  .mtm-1 {
    margin-top: 10px;
  }
  .col-img{
    padding-left: 0;
  }
  h1 {
    font-size: 1.6rem;
}
}
.btn-primary {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.4375rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
