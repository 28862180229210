<template>
  <div
    id="blog-home"
    class="section ptb-130"
    style="background-color: #f8fbfd;"
  >
    <!-- <b-container class="bv-example-row mt-5 mb-5">
      <b-row>
        <b-col cols="8">
    
          <div
            class="mb-5"
            v-for="(post, index) in posts"
            :key="post.slug + '_' + index"
          >
            <router-link :to="'/blog/' + post.slug" class="linka">
              <img
                v-if="post.featured_image"
                :src="post.featured_image"
                alt=""
                class="pb-4 img-fluid"
              />
              <img
                class=""
                v-else
                src="http://via.placeholder.com/250x250"
                alt=""
              />
              <h2 class="title">{{ post.title }}</h2>
              <p class="">{{ post.summary }}</p>
              <p>Continue reading</p>
            </router-link>
          </div>
        </b-col>
        <b-col cols="4"></b-col>
      </b-row>
    
    </b-container> -->

    <b-container class="bv-example-row mt-5 mb-5">
      <div class="row pb-5">
        <div
          class="col-12 col-md-6 col-lg-4 d-flex mb-3"
          v-for="(post, index) in posts"
          :key="post.slug + '_' + index"
        >
          <router-link :to="'/blog/' + post.slug" class="linka">
            <!-- Card -->
            <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">
              <!-- Image -->
              <a class="card-img-top">
                <!-- Image -->

                <img
                  v-if="post.featured_image"
                  :src="post.featured_image"
                  alt=""
                  class="card-img-top"
                />
                <img
                  class="card-img-top"
                  v-else
                  src="http://via.placeholder.com"
                  alt=""
                />
              </a>

              <!-- Body -->
              <a class="card-body">
                <!-- Heading -->
                <h2 class="title">
                  {{ post.title }}
                </h2>

                <!-- Text -->
                <p class="mb-0 text-muted">
                  {{ post.summary }}
                </p>
              </a>

              <!-- Meta -->
              <!-- <a class="card-meta mt-auto"> -->
              <!-- Divider -->
              <!-- <hr class="card-meta-divider" /> -->

              <!-- Avatar -->
              <!-- <div class="avatar avatar-sm mr-2">
                  <img
                    src="assets/img/avatars/avatar-1.jpg"
                    alt="..."
                    class="avatar-img rounded-circle"
                  />
                </div> -->

              <!-- Author -->
              <!-- <h6 class="text-uppercase text-muted mr-2 mb-0">
                  Ab Hadley
                </h6> -->

              <!-- Date -->
              <!-- <p class="h6 text-uppercase text-muted mb-0 ml-auto">
                  <time datetime="2019-05-02">May 02</time>
                </p>
              </a> -->
            </div>
          </router-link>
        </div>
      </div>
      <b-row class="mt-5">
        <b-col sm="3"></b-col>
        <b-col sm="6">
          <h2 class="text-center connected-head">Stay Connected</h2>
          <p class="text-center connected-text">
            Enter a world of technology and receive personalized updates or
            recommendations. Subscribe now.
          </p>
        </b-col>
        <b-col sm="3"></b-col>
      </b-row>
      <b-row>
        <b-col></b-col>
        <b-col sm="8">
          <b-form @submit.stop.prevent="onSubmit" v-if="!issubmitted">
            <b-row>
              <b-col sm="1"></b-col>
              <b-col sm="6">
                <b-form-group>
                  <b-form-input
                    id="email-input"
                    v-model="form.email"
                    type="email"
                    placeholder="Enter email"
                    required
                    class="form-control form-control-lg connected-control"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="5">
                <b-button
                  type="submit"
                  class="btn btn-primary btn-lg connected-submitter"
                  variant="primary"
                  block
                >
                  Submit
                </b-button></b-col
              >
            </b-row>
          </b-form>
          <thankyou message="Thank you for subscribing" v-if="issubmitted"></thankyou>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { butter } from "@/buttercms";
import axios from "axios";
import thankyou from "../components/ThankYou";
export default {
  name: "blog-home",
  components: {
    thankyou,
  },
  data() {
    return {
      issubmitted: false,
      page_title: "Blog",
      posts: [],
      categories: [],
      zapHook: "https://hooks.zapier.com/hooks/catch/7349379/oc2r3zy/",
      form: {
        email: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log(this.form);
      // axios
      //   .post(this.zapHook, this.form, {
      //     headers: { Accept: "application/json" },
      //   })
      
      axios
        .create({
          // eslint-disable-next-line no-unused-vars
          transformRequest: [(data, _headers) => JSON.stringify(data)],
        })
        .post(this.zapHook, this.form)
        .then(function(response) {
          console.log(response);
        });
      this.issubmitted = true;
    },
    getPosts() {
      butter.post
        .list({
          page: 1,
          page_size: 10,
        })
        .then((res) => {
          // console.log(res.data)
          this.posts = res.data.data;
        });
    },
    // getCategories() {
    //   butter.category.list().then((res) => {
    //     console.log("List of Categories:");
    //     console.log(res.data.data);
    //   });
    // },
    // getPostsByCategory() {
    //   butter.category
    //     .retrieve("example-category", {
    //       include: "recent_posts",
    //     })
    //     .then((res) => {
    //       console.log("Posts with specific category:");
    //       console.log(res);
    //     });
    // },
  },
  created() {
    this.getPosts();
    // this.getCategories();
    // this.getPostsByCategory();
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 15px;
}
h2 {
  color: #000;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-size: 1.3rem !important;
}
a:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none !important;
}
a {
  color: rgba(0, 0, 0, 0.75) !important;
}
.connected-head {
  font-size: 35px !important;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.connected-text {
  color: rgba(26, 26, 26, 0.6);
  font-size: 16px;
  margin-bottom: 30px;
  margin: 22px;
}
.connected-control {
  padding: 1.7rem 2rem;
}
.connected-submitter {
  padding: 0.7rem 3.7rem;
  /* width: 220px; */
}

.lift {
  -webkit-transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  transition: box-shadow 0.25s ease, transform 0.25s ease,
    -webkit-transform 0.25s ease;
}

.shadow-light-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05) !important;
}

.card {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f1f4f8;
  border-radius: 0.375rem;
}
.card-body {
  padding: 2rem;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.375rem - 0px);
  border-top-right-radius: calc(0.375rem - 0px);
}
.text-muted {
  color: #869ab8 !important;
}
.card-img-top {
  min-height: 200px;
}
.d-flex {
  display: flex !important;
}
.lift-lg:focus,
.lift-lg:hover {
  box-shadow: 0 2rem 5rem rgba(22, 28, 45, 0.1),
    0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.05) !important;
  transform: translate3d(0, -5px, 0);
}

.ptb-130 {
  padding-top: 130px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ptb-130 {
    padding-top: 20px;
  }
}
</style>
