<template>
  <!-- Page Content -->
  <section class="py-5 pb-7" style="background-color:#F4F4F4;">
    <div class="container mt-5">
      <b-row class="">
        <b-col sm="6" class="text-center my-auto">
          <div class="mr-5">
            <h3 class="mb-5 text-lg-left text-center">
              Calculate a property’s gross rental yield
            </h3>
            <p class="text-lg" style="text-align: justify;">
              It’s easy to work out what an investment property’s gross rental
              yield is. Just plug in your expectations on the right and we’ll
              help you work it out right now!
            </p>
          </div>
        </b-col>
        <b-col sm="6" class="mt-2 mobile-space">
          <div class="row pb-5">
            <div class="col-2"></div>
            <div class="col-10">
              <!-- Card -->
              <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg mt-4">
                <!-- Body -->
                <a class="card-body mt-5 mb-5">
                  <b-form>
                    <b-row>
                      <b-col sm="6" class="mt-2 mobile-space">
                        <label class="" for="inline-form-input-username"
                          >Purchase Price</label
                        >

                        <b-input-group prepend="$" class="mb-3">
                          <b-form-input
                            id="inline-form-input-username"
                            placeholder="500,000"
                            v-model="purchasePrice"
                            class="inputa"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col sm="6" class="mt-2 mobile-space">
                        <label for="inline-form-input-username"
                          >Expected weekly rent</label
                        >
                        <b-input-group prepend="$" class="mb-3">
                          <b-form-input
                            id="inline-form-input-username"
                            placeholder="480"
                            v-model="expectedRent"
                            class="inputa"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="6" class="mt-2 mobile-space">
                        <label for="inline-form-input-username"
                          >Vacancy Rate</label
                        >

                        <b-input-group append="%" class="mb-3">
                          <b-form-input
                            id="inline-form-input-username"
                            placeholder="2"
                            v-model="vacancyRate"
                            class="inputa"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col sm="6" class="mt-2 mobile-space text-white">
                        <label for="inline-form-input-username">0</label>
                        <b-button
                          variant="primary"
                          style="width:100%;padding: 1rem; background-color: rgb(51, 94, 234); border-color: rgb(51, 94, 234); color: white;"
                          v-on:click="calulate()"
                          >Calculate</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                  <!-- Heading -->

                  <div
                    class="card mb-6 mb-lg-0 shadow-light-lg  mt-3"
                    style="background-color:#1bc5bd;"
                    v-if="expectedRentalYeild != 0 && !rotater"
                  >
                    <!-- Body -->
                    <a class="card-body mt-2 mb-2">
                      <!-- Heading -->
                      <span
                        class="card-label font-weight-bolder font-size-h4 text-white"
                        >Expected rental yield</span
                      ><br />

                      <span class="font-weight-bolder font-size-h1 text-white"
                        >{{ expectedRentalYeild }}%</span
                      >
                    </a>
                  </div>
                  <img
                  
                
                    src="../../assets/calc.gif"
                    style="text-align: center;"
                    v-bind:class="{rotate: rotate}"
                    v-if="rotater"
                    alt="Girl in a jacket"
                    width="120"
                    height="120"
                  />

                  <!-- <h2 class="mt-5" v-if="expectedRentalYeild != 0" style="color:#1bc5bd;">
                    Your expected rental yield is
                    <strong>{{ expectedRentalYeild }}%</strong>
                  </h2> -->
                </a>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      rotate: false,
      rotater: true,
      purchasePrice: 0,
      expectedRent: 0,
      vacancyRate: 0,
      expectedRentalYeild: 0,
    };
  },
  methods: {
    calulate() {
      this.rotate = !this.rotate;

       if(this.rotate){
           setTimeout(() => { this.rotater = false  }, 3000);
       }
      let vacancyRate = this.vacancyRate / 100;

      let filledRate = 1.0 - vacancyRate;

      let yearlyFilledRate = 52 * filledRate;

      let annualRental = this.expectedRent * yearlyFilledRate;

      this.expectedRentalYeild = (
        (annualRental / this.purchasePrice) *
        100
      ).toFixed(2);

      // $480 x (52x(1.00-0.02)))/$500000 = 0.0489216 = 4.89%
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 15px;
}
h2 {
  color: #000;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-size: 1.3rem !important;
}
a:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none !important;
}
a {
  color: rgba(0, 0, 0, 0.75) !important;
}
.connected-head {
  font-size: 35px !important;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.connected-text {
  color: rgba(26, 26, 26, 0.6);
  font-size: 16px;
  margin-bottom: 30px;
  margin: 22px;
}
.connected-control {
  padding: 1.7rem 2rem;
}
.connected-submitter {
  padding: 0.7rem 3.7rem;
  /* width: 220px; */
}

.lift {
  -webkit-transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  transition: box-shadow 0.25s ease, transform 0.25s ease,
    -webkit-transform 0.25s ease;
}

.shadow-light-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05) !important;
}

.card {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f1f4f8;
  border-radius: 0.375rem;
}
.card-body {
  padding: 2rem;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.375rem - 0px);
  border-top-right-radius: calc(0.375rem - 0px);
}
.text-muted {
  color: #869ab8 !important;
}
.card-img-top {
  min-height: 200px;
}
.d-flex {
  display: flex !important;
}
.lift-lg:focus,
.lift-lg:hover {
  box-shadow: 0 2rem 5rem rgba(22, 28, 45, 0.1),
    0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.05) !important;
  transform: translate3d(0, -5px, 0);
}

.ptb-130 {
  padding-top: 130px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ptb-130 {
    padding-top: 20px;
  }
}
.inputa {
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  height: calc(1.6em + 1.625rem + 2px);
  border: 1px solid #e9ecef;
  border-radius: 0.375rem;
  box-shadow: none;
}
.inputa-label {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  text-align: left;
}
.input-group-text {
  background-color: #fff;
  border: 1px solid #e9ecef;
  font-size: 1.0625rem;
  font-weight: 400;
  border-radius: 0.375rem;
}
.font-size-h1 {
  font-size: 2rem !important;
}
.font-weight-bolder {
  font-weight: 600 !important;
}
h3 {
  font-size: 2.8rem;
}

.rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}




</style>
