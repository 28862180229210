<template>
  <div>
    
    <Cover />
   
  </div>
</template>

<script>
import Cover from "./Cover.vue"

export default {
  components: {
    Cover,
  },
};
</script>

<style lang="scss" scoped></style>
