<template>
  <div class="pt-5 mt-5 mb-5 head-spacer" style="">
    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-8">
              <span class="text-muted">Updated Yesterday</span>
              <h1 class="my-2">{{ job }}</h1>
              <div class="location">Sydney, NSW</div>

              <router-link
                class="btn btn-primary mt-3"
                :to="{
                  path: '/careers/junior-systems-engineer',
                  hash: '#application',
                }"
                >Apply Now</router-link
              >
              <hr />
              <article class="article">
                <p class="lead">
                  Are you interested in gaining practical experience as a
                  developer? Are you a self starter who’s keen to upskill your
                  abilities and make a real difference to your future?
                </p>
                <p class="unlead">
                  Well now’s the time to show us what you’ve got!
                </p>
                <p class="unlead">
                  We’re looking to add another highly motivated and committed
                  junior developer to our team.
                </p>
                <p class="unlead">
                  At Skipil we want to progress and develop our staff so that
                  our customers are delighted every time.
                </p>
                <h4>Learning is key</h4>
                <p class="unlead">
                  As a full stack developer, you’ll be responsible for building
                  a suite of ready to implement tools for our clients. If you’ve
                  got a degree that's great, but this isn’t a dealbreaker for us
                  - so again let's see what you’ve got and of what you’re capable.
                </p>

                <p class="unlead">
                  Instead of writing a stock standard boring job description
                  telling you the exact skills we’re looking for, we’d prefer
                  that you tell us what it is that you actually know. So, what
                  technologies have you’ve worked with in the past, what would
                  like to work with in the future; what are you learning at the
                  moment and in what are you fluent?
                </p>

                <p class="unlead">
                  A typical week:
                  <br />
                  Researching, learning and developing: 60%<br />
                  Presenting and sharing knowledge with your colleagues
                  (directly and indirectly): 20%<br />
                  Helping and supporting product teams improve their development
                  experience: 20%
                </p>

                <figure>
                  <img
                    src="../../assets/jobs-article.webp"
                    alt="Image"
                    class="rounded"
                  />
                  <!-- <figcaption>A caption to describe the image</figcaption> -->
                </figure>
                <p class="unlead">
                  If you think you have the right qualities to join our team,
                  drop us an application.
                </p>
              </article>
              <hr id="application" />
              <SubmissionForm :job="job" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmissionForm from "../../components/Jobs/SubmissionForm";

export default {
  name: "JuniorDeveloper",
  components: {
    SubmissionForm,
  },
  data() {
    return {
      job: "Junior Developer",
    };
  },
  methods: {},
};
</script>

<style scoped>
.unlead {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}
.article p.lead:first-child {
  margin-top: 4.5rem;
  margin-bottom: 3rem;
}
hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
figure {
  text-align: center;
  margin: 3rem 0;
}

div.rounded,
img.rounded {
  border-radius: 0.625rem !important;
}

figcaption {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #adb5bd;
}

img {
  max-width: 100%;
}
.article p + h4 {
  margin-top: 3rem;
}
.article p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.text-muted {
  color: #adb5bd !important;
}
.btn-primary {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.4375rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.location {
  margin: 0;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .head-spacer {
    padding-top: 1.5rem !important;
  }

  .sa{
    margin-left: 15px;
  }
;
}
</style>
