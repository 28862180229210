<template>
  <div>

     
<footer class="py-5 d-flex flex-column" style="background-color:#000000;">
      <div class="container">
        <b-row>
          <b-col sm="4" class="">
            <p class="text-white text-lg-left text-center" style="margin-left: -15px;">
              2020 copyright, All rights reserved.
            </p>
          </b-col>
          <b-col sm="4" class="mt-2 text-center">
            <a href="https://fb.me/Skipil" class="fab icon-facebook"></a>
            <a href="https://twitter.com/skipil_au" class="fab icon-twitter"></a>
             
            <a
              href="https://www.instagram.com/skipil.io/"
              class="fab icon-instagram"
            ></a>
          </b-col>
          <b-col sm="4" class="mt-2 text-lg-right text-center">
            <b-button
              variant="outline-primary"
              class="btn-contact"
              v-b-modal.modal-contact
              >Contact us</b-button
            >
          </b-col>
        </b-row>
      </div>
    </footer>

 
  </div>
</template>

<script>



export default {
  name: "Footer",
  components: {
    //  thankyou,
   
  },
};
</script>

<style lang="css" scoped>
@import '../css/skipil-icono.css';
.foot {
  padding: 50px 0px 50px 0px;
  background-color: #1a1a1a;
}

.foot-two {
  background-color: #000000;
  color: #ffffff;
}

.fab {
  padding: 10px;
  font-size: 18px;
  width: 38px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  color:#fff;
}

.fab:hover {
  opacity: 0.7;
}

.fa-facebook-f {
  color: #ffffff;
}

.fa-twitter {
  color: #ffffff;
}

.fa-instagram {
  color: #ffffff;
}

.btn-contact {
  border-color: #ffffff;
  color: #ffffff;
}
.btn-contact:hover {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}




@media screen and (max-width: 767px) {
  .btn-contact{
    margin-top: 15px;;
  }
  .btn-submitter{
    width:100%;
  }

}




</style>
